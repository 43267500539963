<template>
  <section>
    <button 
      :class='`w-100 btn-invisible-${buCode} my-1`'
      @click="openModal()">
      <span v-if="reqTax == 1 && pendingEtax == 0 && etaxStatus == 'ER01'"><i class='far fa-edit'></i> แจ้งแก้ไข</span>
      <span v-else-if="reqTax == 1"><i class='far fa-edit'></i> แจ้งแก้ไข</span>
      <span v-else><i class='far fa-edit'></i> แจ้งขอใบกำกับภาษี</span>
    </button>

    <Modal ref="RequestEtaxRef" size="50" :buCode="buCode">
      <template v-slot:header>
        <h3>{{reqTax == 1 ? 'แจ้งแก้ไขใบกำกับภาษี' : 'แจ่้งขอใบกำกับภาษี'}}</h3>
      </template>
      <template v-slot:body>
        <div class="row card mx-0">
          <div class="col-12">
            <p class="h5 mt-3 font-weight-bold text-left px-3">เลือกใบกำกับภาษีของคุณ หรือเพิ่มที่อยู่ใหม่ 
              <span class='float-right' v-if="!isAddNew">
                <button :class="`btn-visible-black`" @click="addNewBillingAdress"><i class="fas fa-plus-circle"></i> เพิ่มที่อยู่ใหม่</button>
              </span>
            </p>
          </div>
          <div class="col-12" v-if="!isAddNew">
            <select class='form-control' v-model="addressNameSelected" @change="addressChanged">
              <option value=''>เลือกที่อยู่ใบกำกับภาษี</option>
              <option v-for="e in listData" :key="e.ANM" :value="e.ANM">{{e.ANM}}</option>
            </select>
          </div>
          <div class="col-12">
            <div class="row px-3">
              <div class="form-group px-2" style="margin-bottom: 10px;margin-top: 10px;">
                    <label class="containerq"> บุคคลธรรมดา
                        <input type="radio" v-model='address.iscom' class="varckradio" name="iscomadd" value="0" checked @click="checkRadioTax">
                        <span :class="`checkmark checkmark-color-${buCode}`"></span>
                    </label>
                </div>
              <div class="form-group px-2" style="margin-bottom: 10px;margin-top: 10px;">
                    <label class="containerq"> นิติบุคคล
                        <input type="radio" v-model='address.iscom' class="varckradio" name="iscomadd"  value="1" @click="checkRadioTax">
                        <span :class="`checkmark checkmark-color-${buCode}`"></span>
                    </label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row px-3">
              <div class="col-4" v-if="address.iscom == 0">
                <label for="" class='m-0'>ชื่อ<span style="margin: 0px;color: red;">*</span></label>
                <input type="text" class='form-control information-detail' v-model="address.namenocom" id="txt-namenocom">
              </div>
              <div class="col-4" v-if="address.iscom == 0">
                <label for="" class='m-0'>นามสกุล<span style="margin: 0px;color: red;">*</span></label>
                <input type="text" class='form-control information-detail' v-model="address.lnamenocom" id="txt-lnamenocom">
              </div>
              <div class="col-4" v-if="address.iscom == 1">
                <label for="" class='m-0'>ชื่อบริษัท<span style="margin: 0px;color: red;">*</span></label>
                <input type="text" class='form-control information-detail' v-model="address.company" id="txt-company">
              </div>
              <div class="col-4" v-if="address.iscom == 1">
                <label for="" class='m-0'>ประเภทสาขา<span style="margin: 0px;color: red;">*</span></label>
                <!-- <input type="text" class='form-control information-detail' v-model="address.idbranch" maxlength="5" id="txt-idbranch"> -->
                <select name="sel-ishq" id="sel-ishq" class='form-control' v-model="address.ishq" @change="changeIsHQ">
                  <option value='Y' >สำนักงานใหญ่</option>
                  <option value='N'>สาขา</option>
                </select>
              </div>
              <div class="col-4">
                <label v-if="address.iscom == 1 && address.ishq == 'N'" for="" class='m-0'>ลำดับที่สาขา<span style="margin: 0px;color: red;">*</span></label>
                <input v-if="address.iscom == 1 && address.ishq == 'N'" type="text" class='form-control information-detail' v-model="address.idbranch" maxlength="5" id="txt-idbranch" placeholder="กรุณาระบุลำดับที่สาขา">
              </div>
              <div class="col-4">
                <label for="" class='m-0'>{{ address.iscom == 0 ? 'เลขบัตรประจำตัวประชาชน' : 'เลขที่ประจำตัวผู้เสียภาษี' }}<span style="margin: 0px;color: red;">*</span></label>
                <input type="text" class='form-control information-detail' v-model="address.idnum" maxlength="13" id="txt-idnum">
              </div>
              <div class="col-4">
                <label for="" class='m-0'>เบอร์ติดต่อ<span style="margin: 0px;color: red;">*</span></label>
                <input type="text" class='form-control information-detail' v-model="address.phone" maxlength="10" id="txt-phone">
              </div>
              <div class="col-4">
                <label for="" class='m-0'>เบอร์สำรอง</label>
                <input type="text" class='form-control information-detail' v-model="address.phone2" maxlength="10">
              </div>
            </div>
            <p class="h5 mt-3 font-weight-bold text-left px-3">ที่อยู่ใบกำกับภาษี</p>
            <hr>
            <div class="row px-3">
              <div class="col-2">
                <label for="" class='m-0'>บ้านเลขที่<span style="margin: 0px;color: red;">*</span></label>
                <input type="text" class='form-control address-detail' v-model="address.adrnum" id="txt-adrnum">
              </div>
              <div class="col-2">
                <label for="" class='m-0'>หมู่ที่</label>
                <input type="text" class='form-control address-detail' v-model="address.moo">
              </div>
              <div class="col-4">
                <label for="" class='m-0'>หมู่บ้าน/อาคาร</label>
                <input type="text" class='form-control address-detail' v-model="address.build">
              </div>
              <div class="col-2">
                <label for="" class='m-0'>ชั้นที่</label>
                <input type="text" class='form-control address-detail' v-model="address.floor">
              </div>
              <div class="col-2">
                <label for="" class='m-0'>ห้องที่</label>
                <input type="text" class='form-control address-detail' v-model="address.room">
              </div>
              <div class="col-4">
                <label for="" class='m-0'>ซอย</label>
                <input type="text" class='form-control address-detail' v-model="address.soi">
              </div>
              <div class="col-4">
                <label for="" class='m-0'>ถนน</label>
                <input type="text" class='form-control address-detail' v-model="address.road">
              </div>
              <div class="col-4">
                <label for="" class='m-0'>รหัสไปรษณีย์<span style="margin: 0px;color: red;">*</span></label>
                <input type="text" class='form-control address-detail' v-model="address.zipcode" maxlength="5" @keyup="changZipcode(address.zipcode)" id="txt-zipcode">
              </div>
              <div class="col-4" v-if="isShowZip">
                <label for="" class='m-0'>จังหวัด<span style="margin: 0px;color: red;">*</span></label>
                <select v-model="address.province" name='sel-province' id='sel-province' class="form-control address-detail" disabled>
                    <option v-for="(e, index) in zipcodeData.pvn" :key="index" :value="e">{{e}}</option>
                </select>
              </div>
              <div class="col-4" v-if="isShowZip">
                <label for="" class='m-0'>อำเภอ<span style="margin: 0px;color: red;">*</span></label>
                <select v-model="address.district" name='sel-district' id='sel-district' class="form-control address-detail" disabled>
                    <option v-for="(e, index) in zipcodeData.dst" :key="index" :value="e">{{e}}</option>
                </select>
              </div>
              <div class="col-4" v-if="isShowZip">
                <label for="" class='m-0'>ตำบล<span style="margin: 0px;color: red;">*</span></label>
                <select v-model="address.subdistrict" name='sel-subdistrict' id='sel-subdistrict' class="form-control address-detail" disabled>
                    <option v-for="(e, index) in zipcodeData.tbn" :key="index" :value="e">{{e}}</option>
                </select>
              </div>
              <div class="col-4" v-if='isAddNew && reqTax == 0'>
                <label for="" class='m-0'>ตั้งชื่อที่อยู่นี้<span style="margin: 0px;color: red;">*</span></label>
                <input type="text" class='form-control address-detail' v-model="address.adrname" maxlength="20" id="txt-adrname">
              </div>
            </div>
            <hr>
            <div class="row m-3">
              <SmsandEmailButton v-if="dataUser.MOBILE" :isTax='isTax' :dataDisplayText="dataUser" type="sms" @setSendBill="setSendBill" :buCode="buCode"/>
            </div>
            <div class="row mx-auto mb-3">
                <button type="button" :class="`btn bt w-100 bg-${buCode}`" @click="confirmRequestEtax">{{reqTax == 0 ? 'ยืนยันแจ้งขอใบกำกับภาษี' : 'ยืนยันแจ้งแก้ไขใบกำกับภาษี'}}</button>
            </div>
          </div>

        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import SmsandEmailButton from '@/components/CheckOut/SmsandEmailButton.vue';
import Modal from '@/components/Modal.vue'
export default {
    name: "MenuHistpryDetailModalRequestEtax",
    data: function() {
      return {
        listData: [],
        addressNameSelected: '',
        address:{
          adrname:'',
          name: '',
          lname: '',
          iscom: 0,
          company: '',
          namenocom: '',
          lnamenocom: '',
          idnum: '',
          idbranch: '',
          phone: '',
          phone2: '',
          adrnum: '',
          build: '',
          floor: '',
          room: '',
          moo: '',
          soi: '',
          road: '',
          subdistrict: '',
          district: '',
          province: '',
          zipcode: '',
          ulat: '',
          ulong: '',
          typeofadr: '',
          othertxt: '',
          havele: '',
          can: '',
          adrstt: '',
          ishq: ''
        },
        saveCurrentZipData:[],
        zipcodeData:{
          tbn:[],
          dst:[],
          pvn:[]
        },
        isShowZip: false,
        isAddNew: false,
        isTax: true,
        isShowModal: false,
        isReceiptBy: false,
        receiptEtaxData: '',
      };
    },
    components: {
      Modal,
      SmsandEmailButton
    },
    props: [
      "cartRef",
      "sorderRef",
      "buCode",
      "pendingEtax",
      "reqTax",
      "etaxStatus",
      "vipId",
      "dataUser",
      "billing"
    ],
    methods: {
      async checkRadioTax () {
        try {
          this.address = {
            adrname:'',
            name: '',
            lname: '',
            iscom: this.address.iscom,
            company: '',
            namenocom: '',
            lnamenocom: '',
            idnum: '',
            idbranch: '',
            phone: '',
            phone2: '',
            adrnum: '',
            build: '',
            floor: '',
            room: '',
            moo: '',
            soi: '',
            road: '',
            subdistrict: '',
            district: '',
            province: '',
            zipcode: '',
            ulat: '',
            ulong: '',
            typeofadr: '',
            othertxt: '',
            havele: '',
            can: '',
            adrstt: '',
            ishq: 'N'
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async changeIsHQ() {
        this.address.idbranch = ''
      },
      async openModal() {
        try {
          this.$refs.RequestEtaxRef.showmodal();
          this.isShowModal = true;
          this.clearAllFields();

          if(this.reqTax == 0) {
            this.getAddress();
          } else {
            $('.information-detail, .address-detail').removeAttr('disabled');
            this.isAddNew = true

            let jsnBill = JSON.parse(this.billing);
            this.address = {
              adrname: jsnBill?.adrname,
              name: jsnBill?.name,
              lname: jsnBill?.lname,
              iscom: jsnBill?.iscom || (jsnBill?.company ? 1 : 0) ,
              company: jsnBill?.company,
              namenocom: jsnBill?.namenocom,
              lnamenocom: jsnBill?.lnamenocom,
              idnum: jsnBill?.idnum,
              idbranch: jsnBill?.idbranch,
              phone: jsnBill?.phone,
              phone2: jsnBill?.phone2,
              adrnum: jsnBill?.adrnum,
              build: jsnBill?.build,
              floor: jsnBill?.floor,
              room: jsnBill?.room,
              moo: jsnBill?.moo,
              soi: jsnBill?.soi,
              road: jsnBill?.road,
              subdistrict: jsnBill?.subdistrict,
              district: jsnBill?.district,
              province: jsnBill?.province,
              zipcode: jsnBill?.zipcode,
              ulat: jsnBill?.ulat,
              ulong: jsnBill?.ulong,
              typeofadr: jsnBill?.typeofadr,
              othertxt: jsnBill?.othertxt,
              havele: jsnBill?.havele,
              can: jsnBill?.can,
              adrstt: jsnBill?.adrstt,
              ishq: jsnBill?.ishq || 'N',
            }
            
            // $('#txt-idnum').attr('disabled', this.etaxStatus != 'ER01' && this.address.idnum != '' && this.address.idnum.length == 13)  

            if(this.address.zipcode != '') {
              await this.changZipcode(this.address.zipcode)
            }
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async getAddress(){
        try {
          let data = {
            VIPID:this.vipId
          };
          let getAPI = await serviceAPI.call_API('post', 'checkout/getMultiDlv', data,'auth');
          if(getAPI.data.dbrows >0){
            this.listData = getAPI.data.dbitems.filter(itm => ['all', 'bill'].includes(itm.STT));
          }
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }

      },
      async addressChanged () {
        try {
          if(this.addressNameSelected == '') {
            this.clearAllFields();
            throw new Error('กรุณาเลือกที่อยู่ใบกำกับภาษี หรือเพิ่มที่อยู่ใหม่')
          } 

          let addressSelected = this.listData.filter(itm => itm.ANM == this.addressNameSelected)
          this.address = {
            adrname: addressSelected[0].ANM,
            name: addressSelected[0].NME,
            lname: addressSelected[0].LNME,
            iscom: addressSelected[0].ISCOM || (addressSelected[0].COM ? 1 : 0),
            company: addressSelected[0].COM,
            namenocom: addressSelected[0].NNC,
            lnamenocom: addressSelected[0].LNNC,
            idnum: addressSelected[0].IDN,
            idbranch: addressSelected[0].IDB,
            phone: addressSelected[0].PHO,
            phone2: addressSelected[0].PHO2,
            adrnum: addressSelected[0].ADR,
            build: addressSelected[0].BLD,
            floor: addressSelected[0].FLR,
            room: addressSelected[0].ROM,
            moo: addressSelected[0].MOO,
            soi: addressSelected[0].SOI,
            road: addressSelected[0].ROAD,
            subdistrict: addressSelected[0].TBN,
            district: addressSelected[0].DST,
            province: addressSelected[0].PVN,
            zipcode: addressSelected[0].ZIP,
            ulat: addressSelected[0].LAT,
            ulong: addressSelected[0].LNG,
            typeofadr: addressSelected[0].TOA,
            othertxt: addressSelected[0].OTT,
            havele: addressSelected[0].HVEL,
            can: addressSelected[0].CAN,
            adrstt: addressSelected[0].STT,
            ishq: addressSelected[0].ISHQ || 'N'
          }

          if(this.address.zipcode != '') {
            await this.changZipcode(this.address.zipcode)
          }

          $('#txt-zipcode').attr('disabled', this.address.zipcode != '')
          $('#txt-adrnum').attr('disabled', this.address.adrnum != '')
          $('#txt-phone').attr('disabled', this.address.phone != '')
          // $('#txt-idnum').attr('disabled', this.address.idnum != '' && this.address.idnum.length == 13)
          $('#txt-idbranch').attr('disabled', this.address.iscom == 1 && this.address.ishq == 'N' && this.address.idbranch != '')
          $('#txt-company').attr('disabled', this.address.iscom == 1 && this.address.company != '')
          $('#txt-namenocom').attr('disabled', this.address.iscom == 0 && this.address.namenocom != '')
          $('#txt-lnamenocom').attr('disabled', this.address.iscom == 0 && this.address.lnamenocom != '')
          $('#sel-province').attr('disabled', this.isShowZip && this.address.province != '')
          $('#sel-district').attr('disabled', this.isShowZip && this.address.district != '')
          $('#sel-subdistrict').attr('disabled', this.isShowZip && this.address.subdistrict != '')
          

        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },
      async clearAllFields() {
        try {
          $('.information-detail, .address-detail').attr('disabled',true);
          this.isShowZip = false;
          this.isAddNew = false;
          this.address = {
            adrname:'',
            name: '',
            lname: '',
            iscom: 0,
            company: '',
            namenocom: '',
            lnamenocom: '',
            idnum: '',
            idbranch: '',
            phone: '',
            phone2: '',
            adrnum: '',
            build: '',
            floor: '',
            room: '',
            moo: '',
            soi: '',
            road: '',
            subdistrict: '',
            district: '',
            province: '',
            zipcode: '',
            ulat: '',
            ulong: '',
            typeofadr: '',
            othertxt: '',
            havele: '',
            can: '',
            adrstt: '',
            ishq: 'N'
          }
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },
      async changZipcode(para_zip){
        try {
          if(para_zip.length==5){
            let data={
              ZIPCODE:para_zip
            }
            let getAPI = await serviceAPI.call_API('post',`checkout/getAddrbyZipcode`,data,'auth');
            if(getAPI.data.dbrows==0){
              throw new Error("ไม่พบรหัสไปรษณีย์ครับ")
            }
            let getData = getAPI.data.dbitems;
            this.saveCurrentZipData = getData;
            let getTAMBON = [];
            let getDISTRICT = [];
            let getPROVINCE = [];
            let oldTAMBON = null;
            let oldDISTRICT = null;
            let oldPROVINCE = null;
            for(let e of getData){
              if (e.TAMBON != oldTAMBON) {
                getTAMBON.push(e.TAMBON.trim())
                oldTAMBON = e.TAMBON.trim();
              }
              if (e.DISTRICT != oldDISTRICT) {
                getDISTRICT.push(e.DISTRICT.trim())
                oldDISTRICT = e.DISTRICT.trim();
              }
              if (e.PROVINCE != oldPROVINCE) {
                getPROVINCE.push(e.PROVINCE.trim())
                oldPROVINCE = e.PROVINCE.trim();
              }
            }
            
            this.zipcodeData={
              tbn:getTAMBON,
              dst:getDISTRICT,
              pvn:getPROVINCE
            }
            this.address.subdistrict = this.address.subdistrict || this.zipcodeData.tbn[0]
            this.address.district = this.address.district || this.zipcodeData.dst[0]
            this.address.province = this.address.province || this.zipcodeData.pvn[0]
            this.isShowZip = true;

            if(this.isAddNew) {
              setTimeout(() => { 
                $('#sel-province, #sel-district, #sel-subdistrict').removeAttr('disabled')
              }, 0)
              
            }
          }else{
            this.isShowZip = false;
            this.zipcodeData={
              tbn:[],
              dst:[],
              pvn:[]
            }
          }
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },
      async confirmRequestEtax() {
        try{
          if(this.reqTax == 0 && !this.isAddNew && this.addressNameSelected == '') {
            throw new Error('กรุณาเลือกที่อยู่ใบกำกับภาษี หรือเพิ่มที่อยู่ใหม่')
          }

          if(this.address.idnum == '') throw new Error('กรุณาระบุเลขบัตรประจำตัวประชาชน/เลขประจำตัวผู้เสียภาษี');

          if(this.address.iscom == 0) {
            if(this.address.namenocom == '') throw new Error('กรุณาระบุชื่อ');
            if(this.address.lnamenocom == '') throw new Error('กรุณาระบุนามสกุล');
          } else {
            if(this.address.company == '') throw new Error('กรุณาระบุชื่อบริษัท');
            if(this.address.ishq == 'N' && this.address.idbranch == '') throw new Error('กรุณาระบุลำดับที่สาขา');
          }

          if(this.address.phone == '') throw new Error('กรุณาระบุเบอร์ติดต่อ');
          if(this.address.adrnum == '') throw new Error('กรุณาระบุบ้านเลขที่');
          if(this.address.zipcode == '') throw new Error('กรุณาระบุรหัสไปรษณีย์');
          if(this.address.province == '') throw new Error('กรุณาระบุจังหวัด');
          if(this.address.district == '') throw new Error('กรุณาระบุอำเภอ');
          if(this.address.subdistrict == '') throw new Error('กรุณาระบุตำบล');
          if(this.reqTax == 0 && this.isAddNew && this.address.adrname == '') throw new Error('กรุณาระบุชื่อที่อยู่');

          if(this.isReceiptBy == '') throw new Error('กรุณาระบุช่องทางการรับใบกำกับภาษี');
          
          await this.sendRequest2STS();
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },
      async setSendBill(sendBill) {
        try {
          this.isReceiptBy = sendBill[0]
          this.receiptEtaxData = sendBill[1]
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },
      async addNewBillingAdress (){
        try {
          this.clearAllFields();
          this.isAddNew = true;
          $('.information-detail, .address-detail').removeAttr('disabled');
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },
      async sendRequest2STS() {
        try {
          let data = {
            address: this.address,
            ref: this.cartRef,
            sorderRef: this.sorderRef,
            receiptEtaxData: this.receiptEtaxData
          }
          let result = await serviceAPI.call_API('post', 'checkout/sendRequestEtax2STS', data,'auth');
          if(result.message != 'OK') {
            throw new Error(result.message)
          }

          serviceMain.showSuccessAlert(this,'คุณจะได้รีบใบกำกับภาษีทางช่องทางที่คุณเลือกไว้','คำขอของคุณถูกส่งให้เจ้าหน้าที่เรียบร้อยแล้ว',true)
          this.$refs.RequestEtaxRef.hidemodal();
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },
      async actionMethod() {
        window.location.reload()
      }
    }
}
</script>

<style>
.bt {
    background-color: #55246c;
    font-weight: bold;
    color: white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #999999;
}

.containerq {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.containerq input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


.containerq:hover input~.checkmark {
    background-color: #ccc;
}


.containerq input:checked~.checkmark {
    background-color: white;
    border-style: solid;
    border-color: #55246c;
}


.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


.containerq input:checked~.checkmark:after {
    display: block;
}


.containerq .checkmark:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #55246c;

}
.varckradio{
  float:left;
}
</style>