<template>
<section>
        <a @click="$router.push({ name: 'History', params: { whereBy: whereBy}, query: { QT: cartRef },})" class="pointer"><span class="back-detail"><i style='font-size:15px;transform: translateY(10%);' class='fas fa-chevron-left'></i> ย้อนกลับ </span></a>
        <p :class="`h5 mt-3 font-weight-bold font-color-${buCodeByOrder}`">รายละเอียดสินค้า 
            <span class='float-right'>
                <PrintHistoryDetail v-if='sostatus && salesource' :buCode="buCodeByOrder" :cartref="cartRef" :listData="listData" :listRows="listRows" :qtSoStatus="sostatus" :qtSaleSource="salesource" :getSumAMT="getSumAMT" :dlvType="dlvType"
                :sumNET="sumNET" :discount="discount" :showDisStaff="showDisStaff"/>
            </span>
        </p>
        <div :id='`history-detail-${cartRef}`'>
          <div class="row px-3 m-0 mt-5">
            <div class="col-12 mb-2 text-center">
              <VueBarcode :value="cartRef">
                ไม่สามารถสร้าง Barcode ได้
              </VueBarcode>
            </div>
          </div>
          <div class="card">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class='text-center' scope="col">เลขที่รายการ</th>
                  <th class='text-center' scope="col">SaleSource</th>
                  <th class='text-center' scope="col">วันที่สั่งซื้อ</th>
                  <th class='text-center' scope="col">สถานะรายการ</th>
                  <th class='text-center' scope="col">จัดส่งโดย</th>
                  <th class='text-center' scope="col">สาขา</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th :class="`font-color-${buCodeByOrder}`">{{ listData[0][0]["#ref"] ? listData[0][0]["#ref"] : ''}}</th>
                  <td class='text-center'>{{ salesource }}</td>
                  <td>{{ listData[0][0]["#orderdate"] }}</td>
                  <td>{{ listData[0][0]["#sttname"] }}</td>
                  <td>{{dlvType.D ? 'จัดส่ง' : ''}}{{dlvType.D && dlvType.P ? '/' : ''}}{{dlvType.P ? 'รับที่สาขา' : ''}}</td>
                  <td>{{ listData[0][0]["#sorderstore"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="col-12 text-center">
            <div class="track px-5"  style="max-width: 90%;">
              <div  class="step step1 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span class="text">ยืนยันใบเสนอราคา</span> </div>
              <div class="line"></div>
              <div  class="step step2 "> <span class="icon">  </span> <span class="text">รอชำระเงิน</span> </div>
              <div class="line lineca"></div>
              <div  class="step step3 "> <span class="icon">  </span> <span class="text">ชำระเงินแล้ว</span> </div>
              <div class="line lineca"></div>
              <div  class="step step4 "> <span class="icon">  </span> <span  class="text">จัดสินค้า</span> </div>
              <div class="line lineca"></div>
              <div  class="step step5 "> <span class="icon">  </span> <span  class="text">จัดส่งสินค้า</span> </div>
              <div class="line lineca"></div>
              <div  class="step step6 "> <span class="icon">  </span> <span  class="text">รับสินค้าแล้ว</span> </div>
            </div>
          </div> -->
          <div :class='`col-12 card mt-3 p-3 bg-${buCodeByOrder}`' v-if="sostatus">
                <StatusHistoryvfix v-if="salesource && salesource=='UV'" :status="sostatus" textClass="text-white"/>
                <StatusHistory v-else :status="sostatus" textClass="text-white"/>
          </div>
          <div class="row px-3 m-0 mt-3 card">
            <div class="col-12 mb-2">
              <p class="h5 mt-3 font-weight-bold">รายละเอียดสินค้า</p>
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">สินค้า</th>
                    <th scope="col" v-if="salesource && salesource=='UV'">ทุน</th>
                    <th scope="col">ราคา</th>
                    <th scope="col">จำนวน</th>
                    <th scope="col">ยอดรวม</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(e, index) in listData[1]" :key="index">
                    <td class="p-0">
                      <ImageBySKU class="py-1" style="max-height: 10vh;" :sku="e.SKC" :buCode="buCodeByOrder" :key="e.SKC" v-if="!(e.SKC == '')"/>
                    </td>
                    <td class="py-1">{{ e.PRN }} <br>({{ e.SKC }}) <br><span v-if="e.SKC != '154338' && e.DLVTEXT != ''">{{ e.DLVTEXT }} <span v-if="e.DLVDATE!=''">วันที่:{{ e.DLVDATE }}</span></span> </td>
                    <td class="py-1" v-if="salesource && salesource=='UV'">{{ e.NORCOST }}</td>
                    <td class="py-1">{{ e.UPC }}</td>
                    <td class="py-1">{{ e.QNT }}</td>
                    <td class="py-1">{{ e.AMT }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row p-0 m-0 mt-3 ">
            <div class="col-12 col-md-6 card">
                <div class='h-100'>
                    <p class="h5 mt-3 font-weight-bold">รายละเอียดการชำระเงิน</p>
                    <table class="table table-borderless">
                        <tbody>
                        <tr>
                            <td class='text-center'>ยอดเงิน</td>
                            <td class='text-center'>{{ getSumAMT }} บาท</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ส่วนลดตามรายการ</td>
                            <td class='text-center'>{{ discount }} บาท</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ส่วนลด MKT</td>
                            <td class='text-center'>{{ listData[0][0]["#discmkt"] }} บาท</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ส่วนลดพนักงาน</td>
                            <td class='text-center'>{{ showDisStaff }} บาท</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ราคาสุทธิ</td>
                            <td class='text-center'>{{ sumNET }} บาท</td>
                        </tr>
                        <tr v-if="salesource && salesource=='UV'">
                            <td class='text-center'>Cost (706)</td>
                            <td class='text-center'>{{ listData[0][0]["#cost706"] }} บาท</td>
                        </tr>
                        <tr v-if="salesource && salesource=='UV'">
                            <td class='text-center'>Total (706)</td>
                            <td class='text-center'>{{ listData[0][0]["#amt706"] }} บาท</td>
                        </tr>
                        <tr v-if="salesource && salesource=='UV'">
                            <td class='text-center'>GP</td>
                            <td class='text-center'>{{ listData[0][0]["#gp"] }} บาท</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div :class="`col-12 col-md-6 card bg-${buCodeByOrder}`">
                <div class='h-100'>
                    <p class="h5 mt-3 font-weight-bold text-white">ข้อมูลการชำระเงิน</p>
                    <p class='text-white'>ไม่พบข้อมูลการชำระเงิน</p>
                </div>
            </div>
          </div>
          <div class='col-12 pm-0 card' v-if="listData[6].length != 0">
            <p class="h5 mt-3 font-weight-bold">ข้อมูลส่วนลด</p>
            <div class='row pm-0'>
                <div class='col-2' style='font-weight:boler;'>
                    Seq
                </div>
                <div class='col-4' style='font-weight:boler;'>
                    Product
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    Quant
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    Disc
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    Remark
                </div>
            </div>
            <div :type='`${listData[6][0].TY}`' class='row pm-0' style=''>
                <div class='col-2' style='font-weight:boler;'>
                    {{ listData[6][0].SEQ }}
                </div>
                <div class='col-4' style='font-weight:boler;'>
                    {{ listData[6][0].NME }}
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    {{ listData[6][0].QNT }}
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    {{ listData[6][0].DSC }}
                </div>
                <div class='col-2' style='font-weight:boler;'>
                    {{ listData[6][0].REM }}
                </div>
            </div>
            <!-- <div class='row pm-0' style=''><div class='col-xs-12' id='noplpp' style='' v-if="listData[6].length == 0">--ไม่พบ PLPP --</div></div> -->
          </div>
          <div :class="`col-12 mt-3 card bg-${buCodeByOrder}`">
            <div class="row m-0 mt-3 mb-3 d-flex align-items-start">
              <div class="col p-0 mx-1 card mb-2">
                <div class='col-12 billaddressh'>
                    <span style='margin:0;font-weight:bolder;font-size:1.2em;'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-truck"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                            />
                          </svg>
                          ที่อยู่จัดส่ง
                    </span>
                </div>
                <table class="table table-borderless table-striped container-fluid p-3 m-0">
                  <tbody>
                    <tr v-for="(e, index) in listData[2]" :key="index">
                      <td class="p-0" style="font-size:1rem;">{{ e.key }}</td>
                      <td class="p-0" style="font-size:1rem;">{{ e.value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col mx-1 card" v-if="listRows[7] != 0">
                <div class='row pm-0' style='background-color:white;border-radius:4px;'>
                    <div class='col-12 billaddressh'>
                        <span style='margin:0;font-weight:bolder;font-size:1.2em;'>
                            <i class="fas fa-sticky-note"></i> ที่อยู่ใบกำกับภาษี/เลขที่ประจำตัวผู้เสียภาษี
                        </span>
                    </div>
                    <table class="table table-borderless table-striped container-fluid p-3 m-0">
                      <tbody>
                        <tr v-for="(e, index) in listData[7]" :key="index">
                          <td class="p-0" style="font-size:1rem;">{{ e.key }}</td>
                          <td class="p-0" style="font-size:1rem;">{{ e.value }}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
            
          </div>
          <div class="col-12 my-3 card " v-if='!["", "C", "W"].includes(listData[0][0]["#sorderstatus"]) && listData[0][0]["#qtetax"]'>
            <p class="h5 mt-3 font-weight-bold">{{reqTax == 1 || pendingEtax == 1 ? 'ใบกำกับภาษีเต็มรูปแบบ' : 'ใบเสร็จรับเงิน'}}</p> 
            <span 
              :class="`text-center w-100 my-1`" 
              style="background-color: lightgray; border-radius: 4px;" 
              v-if="pendingEtax == 1 || (pendingEtax == 0 && (!sendEtax || etaxStatus == 'ER01'))">
                <span v-if="reqTax == 1 && pendingEtax == 0 && etaxStatus == 'ER01' && canEditEtax">ข้อมูลใบกำกับภาษีไม่ถูกต้อง กรุณาแก้ไขข้อมูล"</span>
                <span v-else-if="reqTax == 1 && pendingEtax == 0 && etaxStatus == 'ER01' && !canEditEtax">ข้อมูลใบกำกับภาษีไม่ถูกต้อง กรุณาติดต่อแอดมินเพื่อดำเนินการแก้ไข"</span>
                <span v-else>
                  {{reqTax == 1 && pendingEtax == 1 ? 'กำลังดำเนินการออกใบกำกับภาษี' : 'กำลังดำเนินการออกใบเสร็จ'}}
                </span>
            </span>
            <a target="_blank"  
              :download="etaxLink"
              :href="etaxLink"
              :class='`btn bt w-100 bg-${buCodeByOrder} my-1 ${canDownloadEtax ? "": "disabled"}`' 
              v-if="[0,2].includes(pendingEtax) && etaxLink != '#' && etaxStatus != 'ER01'">
              <i class='fas fa-download'></i> {{reqTax == 1 ? 'ดาวน์โหลด' : 'ดาวน์โหลดใบเสร็จรับเงิน'}}
            </a>
            <MenuHistpryDetailModalRequestEtax 
              v-if="pendingEtax == 0 && etaxLink != '#' && canEditEtax"
              :cartRef="cartRef" 
              :sorderRef="listData[0][0]['#sorderRef']"
              :vipId="vipId" 
              :buCode="buCodeByOrder" 
              :pendingEtax="pendingEtax" 
              :reqTax="reqTax" 
              :etaxStatus="etaxStatus" 
              :dataUser="dataUser"
              :billing="listData[0][0]['#jsonbill']" 
            />
          </div><br>
        </div>
        
              <!-- v-if="[0,2].includes(pendingEtax) && sendEtax" -->
        <!-- <button type="button" :class="`btn bt w-100 bg-${getBU}`" data-dismiss="modal" @click="goPrint()">พิมพ์ใบยืนยันการชำระเงิน</button> -->
    </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import Modal from '@/components/Modal.vue'
import ImageBySKU from '@/components/ImageBySKU.vue';
import printJS from 'print-js'
import StatusHistoryvfix from '@/components/Menu/StatusHistoryvfix.vue';
import StatusHistory from '@/components/Menu/StatusHistory.vue';
import PrintHistoryDetail from '@/components/Menu/PrintHistoryDetail.vue';
import MenuHistpryDetailModalRequestEtax from '@/components/Menu/MenuHistpryDetailModalRequestEtax.vue'

export default {
    name: "MenuHistoryDetail",
    data: function () {
        return {
            listData: [[{
                    "#ref": "",
                    "#orderdate": "",
                    "#dscamt": Number,
                    "#discplpp": "",
                    "#discmkt": "",
                    "#gtotamt": Number,
                    "#amt706": Number,
                    "#cost706": Number,
                    "#gpamount": Number,
                    "#gp": Number,
                    "#custid": "",
                    "#custname": "",
                    "#mobile": "",
                    "#ticket": "",
                    "#postime": "",
                    "#sstatus": "",
                    "#sorderstore": "",
                    "#sorderstatus": "",
                    "#sttname": "",
                    "#dlvcode": "",
                    "#dlvtype": "",
                    "#stcode": "",
                    "#DiscID": Number,
                    "#DISCCODE": "",
                    "#DiscRate": Number,
                    "#DiscType": "",
                    "#discstaff": "",
                    "SkuList": "",
                    "dlvaddr": "",
                    "storeaddr": "",
                }],[{
                    "SEQ": Number,
                    "PRN": "",
                    "SKC": '',
                    "QNT": Number,
                    "UPC": Number,
                    "AMT": Number,
                    "SKUSEQ": Number,
                    "NORCOST": "",
                    "SHOWCOST": "",
                    "CAT_CODE": "",
                    "PR_VAT": "",
                    "COST706": Number,
                    "AMT706": Number,
                    "GP": Number
                }],[{
                    "key": "",
                    "value": "",
                    "seq": Number
                }],[],[],[{
                    "key": "",
                    "value": ""
                }],[{
                    "SEQ": Number,
                    "SKC": Number,
                    "NME": "",
                    "QNT": Number,
                    "AMT": Number,
                    "DSC": Number,
                    "TY": "",
                    "REM": "",
                    "SKUSEQ": Number
                }],[{
                    "key": "",
                    "value": "",
                    "seq": Number
                }]],
                listRows:[],
                salesource: '',
                sostatus: '',
                buCodeByOrder: '',
                pendingEtax: 0,
                sendEtax: '',
                etaxLink: '',
                etaxStatus: '',
                reqTax: 0,
                vipId: '',
                dataUser: {
                  MOBILE: '',
                  EMAIL: ''
                },
                dlvType:{P:false, D:false},
                discount:0,
                sumNET:0,
                listDataAMT:[],
                DisStaff:0,
                showDisStaff:'',
            }
        },
    components: {
        Modal,
        ImageBySKU,
        StatusHistory,
        StatusHistoryvfix,
        PrintHistoryDetail,
        MenuHistpryDetailModalRequestEtax
    },
    props: [
        "whereBy",
        "cartRef",
        "qtSoStatus",
        "qtSaleSource",
        "buCode",
        "stcode"
    ],
    mounted() {
        try {
            this.startHistory()
        } catch (error) {
            serviceMain.showErrorAlert(this,error.message)
        }
        
    },
    methods: {
        async startHistory(){
            //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
            let skc = this.cartRef;
            let STCODE = null//this.stcode;
            // console.log(skc+' '+STCODE);
            await this.detailHis(skc, STCODE, 0);
            await this.detailHis(skc, STCODE, 1);
            await this.detailHis(skc, STCODE, 2);
            await this.detailHis(skc, STCODE, 5);
            await this.detailHis(skc, STCODE, 6);
            await this.detailHis(skc, STCODE, 7);
            await this.getOrderDetail()
            await this.checkListDlv()

        },
        
        async detailHis(skc, STCODE, mode) {
            try {
                let data = {
                "sku": `${skc}`,
                "STCODE": STCODE,
                "mode": mode
                }
                let getAPI = await serviceAPI.call_API('post', `history/getQuotationDetail`, data, 'auth');
                this.listData[mode] = getAPI.data.dbitems;
                this.listRows[mode] = getAPI.data.dbrows;

                if(mode == 0) {
                    this.sostatus = this.qtSoStatus || this.listData[0][0]['#sostatus'];
                    this.salesource = this.qtSaleSource || this.listData[0][0]['#salesource'];
                    this.buCodeByOrder = this.buCode || this.listData[0][0]['#buCode'];
                    this.pendingEtax = this.listData[0][0]['#pendingreqetax'];
                    this.sendEtax = this.listData[0][0]['#sendetax'];
                    this.etaxStatus = this.listData[0][0]['#etaxStatus'];
                    this.etaxLink = this.listData[0][0]['#etaxLink'];
                    this.reqTax = this.listData[0][0]['#reqTax'];
                    this.vipId = this.listData[0][0]['#custid'];
                    this.dataUser.EMAIL = this.listData[0][0]['#email'];
                    this.dataUser.MOBILE = this.listData[0][0]['#mobile'];
                }

            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        async getOrderDetail() {
          try {
            let data = {
              "ref": this.cartRef
            }
            // let getAPI1 = await serviceAPI.call_API('post', `history/orderdetail`, data, 'auth');
            // this.listDataOrder = getAPI1.data.dbitems;

            let getAPI2 = await serviceAPI.call_API('post', `history/getPriceAndRemarkByVfix`, data, 'auth');
            this.listDataAMT = getAPI2.data.dbitems[0];

            // let dataDIS = {
            //   "ref": this.cartRef,
            //   "stc": this.stcode
            // }
            // let getAPI3 = await serviceAPI.call_API('post', `history/myplpp`, dataDIS, 'auth');
            // this.listDataDIS = getAPI3.data.dbitems;

            let dataStaff = {
              REF:this.cartRef,
              STCODE:this.stcode ,
            }
            let getAPIStaff = await serviceAPI.call_API('post', `checkout/getDiscMKTStaffResult`, dataStaff, 'auth');
            this.DisStaff = getAPIStaff.data.dbitems[0].Staffdisc;
            this.showDisStaff = await this.priceBath(this.DisStaff);
            let discplpp = await this.price2Num(this.listDataAMT.discplpp)
            let otpdisc = await this.price2Num(this.listDataAMT.otpdisc)
            let discountAMT = await this.price2Num(this.listDataAMT.discount)
            this.discount =  await this.priceBath(discplpp + otpdisc)
            let totallist = await this.price2Num(this.listDataAMT.total)
            this.sumNET = await this.priceBath(totallist - this.DisStaff)
          } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
          }
        },
        async priceBath(para_price) {
          return para_price.toLocaleString(undefined, {minimumFractionDigits: 2})
        },
        async price2Num(para_price) {
          return Number(para_price.replace(/[^0-9.-]+/g,""))
        },
        async checkListDlv(){
          try {
            this.dlvType={P:false, D:false}
            if(!this.listData[0][0]['SkuList']){
              return
            }
            let listDlv =  JSON.parse(this.listData[0][0]['SkuList'])
            for (let i = 0; i < listDlv.length; i++) {
              let carrier = listDlv[i].trackingNo ? JSON.parse(listDlv[i].trackingNo)[0].carrier : ''
              let listSku = listDlv[i].skulist ? JSON.parse(listDlv[i].skulist) : ''
              let nitems = listDlv[i].nitems
              for (let j = 0; j < nitems; j++) {
                for (let k = 0; k < this.listData[1].length; k++) {
                  // console.log(listSku[j].SEQ,this.listData[1][k].SEQ);
                  if(listSku[j].SEQ == this.listData[1][k].SKUSEQ){
                  // if(listSku[j].SKC == this.listData[1][k].SKC){
                    this.listData[1][k].DLV = carrier
                    this.listData[1][k].deliveryByStore = listSku[j].deliveryByStore
                    if(carrier == 'PICK'){
                      this.listData[1][k].DLVDATE = JSON.parse(listDlv[i].trackingNo)[0].pickupDate
                      this.listData[1][k].DLVTEXT = `รับที่สาขา ${listSku[j].deliveryByStore}`
                      this.dlvType.P = true
                    }else{
                      this.listData[1][k].DLVDATE = JSON.parse(listDlv[i].trackingNo)[0].deliveryDate
                      this.listData[1][k].DLVTEXT = `จัดส่งโดยสาขา ${listSku[j].deliveryByStore == '60004' ? 'DC' : listSku[j].deliveryByStore} (${carrier})`
                      if(carrier == 'VEN'){
                        this.listData[1][k].DLVTEXT = `จัดส่งโดยVendor`
                      }else if(carrier == 'DCS'){
                        this.listData[1][k].DLVTEXT = `จัดส่งโดย DC `
                      }
                      this.dlvType.D = true
                    }
                  }
                }
              }
              
            }
          } catch (error) {
              serviceMain.showErrorAlert(this, error.message)
          }
        },
        goPrint(){
            printJS({
                printable: 'history-detail-'+this.cartRef,
                type: 'html',
                //properties: [
                // {field:'name', displayName:'name', columnSize:`50%`},
                // {field:'sex', displayName:'sex',columnSize:`50%`},
                //],
                // header: `<p class="custom-p"> list </p>`,
                // style: '#printCons {width: 600px;} .no-print{width: 0px} .itemText1 { width: 200px } .itemText2 { width: 200px } .itemText3 { width: 200px } .itemText4 { width: 200px }',
                // gridHeaderStyle:'font-size:12px; padding:3px; border:1px solid; font-weight: 100; text-align:left;',
                // gridStyle:'font-size:12px; padding:3px; border:1px solid; text-align:left;',
                // repeatTableHeader: true,
                // scanStyles:true,
                targetStyles: ['*'],
                ignoreElements:['no-print','bc','gb']
            })
        }
    },
    computed:{
        getBU:function(){
            return this.$store.getters['customer/getQTBU']
        },
        getSumAMT:function(){
            let sum = 0
            for (let i = 0; i < this.listData[1].length; i++) {
            sum = sum + this.listData[1][i].AMT;
            }
            return sum
        },
        canEditEtax: function() {
          let profile = this.$store.getters['auth/getQTPROFILE'];
          return profile.includes('0116');
        },
        canDownloadEtax: function() {
          let profile = this.$store.getters['auth/getQTPROFILE'];
          return profile.includes('0117');
        }
        }
}
</script>

<style scoped lang="scss">
.input-History{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.track {
     position: relative;
     background-color: none;
     height: 1px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     margin-bottom: 50px;
     margin-top: 40px;
     //margin-left:-40px;
 }

 .line {
     position: relative;
     background-color: #CCC;
     height: 1px;
     width: 40%;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
 }

 .track .step {
     -webkit-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1;
     width: 14%;
     margin-top: -18px;
     text-align: center;
     position: relative
 }

 .track .step.active .icon {
    background: #5cb85c;
    color: #fff;
    padding-top: 5px;
    font-size: 18px;
 }
 
 .track .step.cancel .icon {
     background: #55246c;
     color: #fff
 }

 .track .step.none .icon {
     background: none;
     color: #fff
 }

 .track .icon {
     display: inline-block;
     width: 30px;
     height: 30px;
     line-height: 30px;
     position: relative;
     border-radius: 100%;
     background: #ddd
 }

 .icon > .fa {
     font-size: 15px !important;
     margin-top: 8px;
 }

 .track .step.active .text {
     font-weight: 400;
     color: #000;
 }

 .lineca.none{
     background: none;
 }

 .track .text {
     font-size: 15px !important;
     display: block;
     margin: 7px -20px 0 -20px;
 }
 .track .textr {
     font-size: 15px !important;
     display: block;
     margin: 7px -20px 0 -20px;
 }
.bt {
    background-color: #55246c;
    font-weight: bold;
    color: white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
.print-Div{
  width: 1000px;
}
button:disabled,
button[disabled]{
  cursor: not-allowed;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>